<template>
  <div style="text-align: justify">
    <h2 class="mb-4">Enthalpy of Reactions - Prelab Exercise Part I</h2>

    <p class="mb-6">
      The heat of neutralization is the heat of reaction when one mole of acid reacts with one mole
      of base to create one mole of salt and one mole of water.
    </p>

    <p class="mb-3">
      Please enter your exercise number below. Without this number, you will automatically receive a
      grade of 0 on your prelab. If this does not match the number on the prelab you hand in, you
      will receive a grade of 0.
    </p>

    <calculation-input
      v-model="inputs.exerciseNumber"
      class="mb-6"
      prepend-text="$\text{Exercise Number:}$"
      :disabled="!allowEditing"
    />

    <p class="mb-1">
      <u>Data:</u>
    </p>

    <v-simple-table style="margin-left: 15px; margin-bottom: 20px">
      <tbody>
        <tr>
          <td style="vertical-align: middle; text-align: left">
            Mass of <latex-number :number="acidBaseConc" unit="\text{M}" /> HCl used
          </td>
          <td
            v-if="exNumber >= 101 && exNumber <= 900"
            style="vertical-align: middle; text-align: left"
          >
            <stemble-latex content="$100.0\text{ g}$" />
          </td>
          <td v-else style="font-weight: bold; color: #c50000">Invalid Exercise Number</td>
        </tr>
        <tr>
          <td style="vertical-align: middle; text-align: left">
            Mass of <latex-number :number="acidBaseConc" unit="\text{M}" /> NaOH used
          </td>
          <td v-if="exNumber >= 101 && exNumber <= 900" style="vertical-align: middle">
            <stemble-latex content="$100.0\text{ g}$" />
          </td>
          <td v-else style="font-weight: bold; color: #c50000">Invalid Exercise Number</td>
        </tr>
        <tr>
          <td style="vertical-align: middle; text-align: left">
            Temperature of solutions before mixing
          </td>
          <td v-if="exNumber >= 101 && exNumber <= 900" style="vertical-align: middle">
            <latex-number :number="Ti" unit="^\circ\text{C}" />
          </td>
          <td v-else style="font-weight: bold; color: #c50000">Invalid Exercise Number</td>
        </tr>
        <tr>
          <td style="vertical-align: middle; text-align: left">
            Temperature of solutions after mixing
          </td>
          <td v-if="exNumber >= 101 && exNumber <= 900" style="vertical-align: middle">
            <latex-number :number="Tf.toFixed(2)" unit="^{\circ}\text{C}" />
          </td>
          <td v-else style="font-weight: bold; color: #c50000">Invalid Exercise Number</td>
        </tr>
        <tr>
          <td style="vertical-align: middle; text-align: left">
            Specific heat of <latex-number :number="saltConc.toFixed(3)" unit="\text{M}" /> aq. NaCl
          </td>
          <td v-if="exNumber >= 101 && exNumber <= 900" style="vertical-align: middle">
            <stemble-latex content="$3.955\text{ J g}^{-1}\,^{\circ}\text{C}^{-1}$" />
          </td>
          <td v-else style="font-weight: bold; color: #c50000">Invalid Exercise Number</td>
        </tr>
        <tr>
          <td style="vertical-align: middle; text-align: left">Heat capacity of the calorimeter</td>
          <td v-if="exNumber >= 101 && exNumber <= 900" style="vertical-align: middle">
            <latex-number :number="Ccal.toFixed(2)" unit="\text{J/}^\circ\text{C}" />
          </td>
          <td v-else style="font-weight: bold; color: #c50000">Invalid Exercise Number</td>
        </tr>
      </tbody>
    </v-simple-table>

    <p class="mb-3">
      Use the following data to determine the heat of neutralization (in kJ/mol) of
      <chemical-latex content="HCl" /> by <chemical-latex content="NaOH\text{.}" /> For this
      problem, you may assume that the densities of the solutions of
      <chemical-latex content="HCl" /> and <chemical-latex content="NaOH" /> are
      <latex-number number="1.000" unit="\text{g/mL.}" />
    </p>

    <v-row>
      <v-col cols="5">
        <calculation-input v-model="inputs.qNeut" :disabled="!allowEditing" />
      </v-col>
      <v-col cols="3">
        <v-text-field v-model="inputs.units" prefix="Units:" outlined :disabled="!allowEditing" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';
import LatexNumber from '../displayers/LatexNumber';
import ChemicalLatex from '../displayers/ChemicalLatex';
import {seededRandomBetween} from '@/courses/utils/seededShuffle';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';

export default {
  name: 'UPEICalorimetryPreLabPt1V2',
  components: {
    CalculationInput,
    ChemicalLatex,
    StembleLatex,
    NumberValue,
    LatexNumber,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        exerciseNumber: null,
        qNeut: null,
        units: null,
      },
    };
  },
  computed: {
    exNumber() {
      if (this.inputs.exerciseNumber) {
        return this.inputs.exerciseNumber;
      } else {
        return 0;
      }
    },
    saltConc() {
      return seededRandomBetween(this.exNumber, 0.5, 0.53, 2);
    },
    Ccal() {
      return seededRandomBetween(this.exNumber * 2, 43, 44, 2);
    },
    Tf() {
      return seededRandomBetween(this.exNumber * 3, 25, 28, 2);
    },
    qNeut() {
      return seededRandomBetween(this.exNumber * 4, -59, -56, 1);
    },
    acidBaseConc() {
      return (this.saltConc * 2).toFixed(2);
    },
    Ti() {
      return (
        (this.qNeut * 100 * this.acidBaseConc) / (3.955 * 200.0 + this.Ccal) +
        this.Tf
      ).toFixed(2);
    },
  },
};
</script>
